<template>
  <div id="about-page" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <!-- Start Page Title Area -->
    <div class="page-title-area padding-50-pr bg-about">
      <div class="container">
        <div class="page-title-content">
          <h2>"PeaceJam brings joy to the hard work of changing the world."  - Desmond Tutu</h2>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->
    <div class="loading" style="height: 700px;" v-if="about_data === undefined && !about_error">
      <Loading />
    </div>
    <div v-if="about_data">

      <section class="p-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 d-flex align-items-center">
              <div class="text-section">
                <div class="about-area-content">
                  <span>Our Vision</span>
                </div>
                <br/>
                <div class="about-tab">
                  <p class="white-space-pre-wrap" v-html="about_data.data[0].our_vision"></p>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <AboutImage :id="about_data.data[0].image" />
            </div>
          </div>
        </div>
      </section>

      <section class="events-section p-0">
        <div class="container-fluid">
          <div class="row flex-lg-row flex-column-reverse">
            <div class="col-lg-6">
              <AboutImage :id="about_data.data[0].mission_image" />
            </div>
            <div class="col-lg-6 d-flex align-items-center">
              <div class="text-section">
                <div class="about-area-content">
                  <span>Our Mission</span>
                  <h3>{{about_data.data[0].mission_title}}</h3>
                </div>

                <div class="about-tab">
                  <p class="white-space-pre-wrap" v-html="about_data.data[0].mission_text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="p-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 d-flex align-items-center">
              <div class="text-section">
                <div class="about-area-content">
                  <span>Our Story</span>
                  <h3>{{about_data.data[0].story_title}}</h3>
                </div>

                <div class="about-tab">
                  <p class="white-space-pre-wrap" v-html="about_data.data[0].story_text"></p>
                </div>

                <div class="youtube-video">
                  <a @click="openModal" class="btn btn-primary" bis_skin_checked="1"><i class="flaticon-curve-arrow-1"></i> Watch Video</a>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <AboutImage :id="about_data.data[0].story_image" />
            </div>
          </div>
        </div>
      </section>
    </div>


    <!-- Financial Information -->
    <section id="financial" >
      <div class="container">
        <div class="row">
          <div class="col-12">
              <h2>Financial Information</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 document-card">
            <div class="row">
              <div class="col-3">
                <img src="../assets/img/financial/PeaceJamForm990.png" width="60px"/>
              </div>
              <div class="col-8">
                <p>PeaceJam Form 990</p>
                <a href="https://billionacts.blob.core.windows.net/billion-act-assets/PeaceJam/990%20Return%20of%20Organziation%20Exempt%20from%20Income%20tax%20%20PeaceJam%20Foundation%202022.pdf" target="_blank" rel="noreferrer" class="events-btn-one">
                  <i class="flaticon-curve-arrow"></i> View
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 document-card">
            <div class="row">
              <div class="col-3">
                <img src="../assets/img/financial/AnnualReport.png" width="60px"/>
              </div>
              <div class="col-8">
                <p>PeaceJam Annual Report</p>
                <a href="/PeaceJam Annual Report 2023 compressed.pdf" target="_blank" rel="noreferrer" class="events-btn-one">
                  <i class="flaticon-curve-arrow"></i> View
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 document-card">
            <div class="row">
              <div class="col-3">
                <img src="../assets/img/financial/AuditedFinancials.png" width="60px"/>
              </div>
              <div class="col-8">
                <p>Audited Financials</p>
                <a href="https://billionacts.blob.core.windows.net/billion-act-assets/PeaceJam/2022%20Audit%20Report%20for%20PeaceJam%20Foundation.pdf" target="_blank" rel="noreferrer" class="events-btn-one">
                  <i class="flaticon-curve-arrow"></i> View
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End of Financial -->

    <div class="video-url-modal" :class="{'show': showModal}">
      <iframe width="100%" height="100%" :src="modalVideoUrl" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
      <div class="close-modal" @click="closeModal">
        <i class="flaticon-plus"></i>
      </div>
    </div>


  </div>
</template>

<script>
  import api from "../services/api";
  import useSWRV from "swrv";
  import Loading from "../components/Loading";
  import Vue from "vue";
  import AboutImage from "../components/AboutImage";

  export default {
    name: "About",
    components: {AboutImage, Loading},
    data() {
      return {
        modalVideoUrl: '',
        showModal: false
      }
    },
    setup() {
      let {
        data: about_data,
        error: about_error,
        isValidating: about_isValidating
      } = useSWRV(() => `items/about?status=published`, api.fetcher);

      return {
        about_data,
        about_error,
        about_isValidating,
      }
    },
    async mounted() {
      window.scrollTo(0, 0);
    },
    methods: {
      async openModal() {
        this.showModal               = true;
        document.body.style.overflow = 'hidden';
        this.modalVideoUrl           = await this.getVideoThumbnail(this.about_data.data[0].video_url);
      },
      closeModal() {
        this.showModal               = false;
        document.body.style.overflow = 'auto';
        this.modalVideoUrl           = ''
      },
      async getVideoThumbnail (url) {
        var videoObj = this.parseVideo(url);
        if (videoObj.type === 'youtube') {
          return 'https://www.youtube.com/embed/' + videoObj.id;
        } else if (videoObj.type === 'vimeo') {
          let vimeoUrl = await this.getVimeoVideoId(url);
          if (vimeoUrl) {
            return this.getVimeoVideoUrl(vimeoUrl.data.html)
          } else {
            return url;
          }
        }
      },
      parseVideo (url) {
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
          var type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
          var type = 'vimeo';
        }

        return {
          type: type,
          id: RegExp.$6
        };
      },
      getVimeoVideoUrl(iframe) {
        var tmp = document.createElement('div');
        tmp.innerHTML = iframe;
        return  tmp.querySelector('iframe').getAttribute('src');
      },
      getVimeoVideoId: async (url) => {
        return Vue.axios
          .get(`https://vimeo.com/api/oembed.json?url=${url}`)
          .then(res => res)
          .catch(() => null);
      },
    }
  }
</script>

<style scoped lang="scss">
  .youtube-video {
    margin-top:30px;
  }

  .youtube-video a {
    color:#fff;
  }

  #our-story {
    padding-bottom: 60px;
  }

  .video-section {
    background-color: #effafc;

    .video-frame {
      position: relative;
      width: 100%;
      margin-top: -300px;
      cursor: pointer;

      &:hover .play-btn {
        transform: translate(-50%, -50%) scale(1.1);
      }

      img {
        width: 100%;
      }

      .play-btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border: 3px solid #fff;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.45098039215686275);
        cursor: pointer;
        transition: 0.5s;

        img {
          width: 50px;
        }
      }
    }
  }

  .about-image {
    margin-left: -15px;
    margin-right: -15px;
    min-height: 400px;
    height: 100%;
    background-size: cover;
    background-position: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .about-area-content {
    margin: 0;
  }
  .text-section {
    padding: 50px 5%;
  }



  .video-url-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.71);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .close-modal {
      position: fixed;
      z-index: 99999;
      top: 10px;
      right: 10px;
      color: #000;
      font-size: 25px;
      background: #ffffffde;
      width: 40px;
      border-radius: 50%;
      height: 40px;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width: 992px) {
    #our-mission .row {
      flex-direction: column-reverse;
    }

    .default-img {
      margin-top: 25px;
      height: auto !important;
    }

    .video-section .video-frame {
      margin-top: 0;
    }

    #our-story {
      padding-bottom: 50px;
    }

    .others-section {
      background: linear-gradient( #31bfd7, #0fdbaf);
    }

    .others-section a {
      font-size: 18px;
      img {
        width: 34px;
      }
    }
    .others-section .background:before {
      content: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .others-two-content {
      margin-bottom: 30px;
      text-align: center;
    }
    .others-section a {
      min-width: 200px;
      text-align: left;
    }
  }
</style>
