<template>
  <div
    v-if="image_data"
    class="about-image"
    :style="`background-image: url('` + image_data.data.data.thumbnails[1].url + `')`"
  >
    <div class="about-image-inset" :style="`background-image: url('` + image_data.data.data.full_url + `')`"></div>
  </div>
  <div
    v-else-if="image_error || image_data === null"
    class="about-image"
    :style="`background-image: url('` + require('../assets/img/default-img.jpg') + `')`"
  ></div>
</template>

<script>
  import api from '../services/api';
  import useSWRV from "swrv";

  export default {
    name: "AboutImage",
    props: ['id'],
    setup(props) {
      let {
        data: image_data,
        error: image_error
      } = useSWRV(() => `/files/${props.id }?status=published`, api.fetcher);

      return {
        image_data,
        image_error
      }
    },
  }
</script>

<style scoped>
  .about-image-inset {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
  }
</style>
